import instance from "@/utils/useAxiosApi";

export function postLogin(params: AnyObject = {}) {
    return instance({
        url: `/mobile/user/isLogin`,
        method: 'POST',
        data: params
    });
}

export function getUserInfo(params: AnyObject = {}) {
    return instance({
        url: `/mobile/user/getUserInfo`,
        method: 'POST',
        data: params
    });
}

export function getUserLeaveInfo(params: AnyObject = {}) {
    return instance({
        url: `/mobile/userLeave/getUserBasicInfo`,
        method: 'POST',
        data: params
    });
}
export function bannerList(params: AnyObject = {}) {
    return instance({
        url: `/mobile/index/bannerList`,
        method: 'POST',
        data: params
    });
}
//金刚区列表
export function getKingKongClientList(params: AnyObject = {}) {
    return instance({
        url: `/mobile/operate/king/kong/getKingKongClientList`,
        method: 'POST',
        data: params
    });
}

export function queryGuide(params: AnyObject = {}) {
    return instance({
        url: `/mobile/op/guide/queryGuide`,
        method: 'POST',
        data: params
    });
}

//旧版获取公开课列表接口
export function getAllCourseList(params: AnyObject = {}, currentPage: number) {
    return instance({
        url: `/mobile/course/getAllCourseList?currentPage=${currentPage}`,
        method: 'POST',
        data: params
    });
}

//新版获取公开课标签
export function getOpenCourseTags(params: AnyObject = {}) {
    return instance({
        url: `/mobile/opencourse/getOpenCourseTags`,
        method: 'POST',
        data: params
    });
}
//新版获取公开课列表
export function queryOpenCoursePage(params: AnyObject = {}) {
    return instance({
        url: `/mobile/opencourse/queryOpenCoursePage`,
        method: 'POST',
        data: params
    });
}

export function querySubject(params: AnyObject = {}) {
    return instance({
        url: '/mobile/subject/query',
        method: 'POST',
        data: params
    });
}

export function WeChatSignature(params: AnyObject = {}) {
    return instance({
        url: '/mobile/share/WeChatSignature',
        method: 'POST',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    });
}

// 获取服务器时间
export function getDate(params: AnyObject = {}) {
    return instance({
        url: "/mobile/common/getDate",
        method: "POST",
        params
    });
}


//获取所有专业科目
export function querySubjectList(params: AnyObject = {}) {
    return instance({
        url: "/mobile/subject/querySubjectList",
        method: "POST",
        params
    });
}

//获取用户最后选择的专业
export function getUserLastSelectSubject(params: AnyObject = {}) {
    return instance({
        url: "/mobile/research/mock/getUserLastSelectSubject",
        method: "POST",
        params
    });
}

//保存用户选择的专业/科目
export function saveSelectSubject(params: AnyObject = {}) {
    return instance({
        url: "/mobile/research/mock/saveSelectSubject",
        method: "POST",
        params
    });
}

export function getCommonJson(params: AnyObject = {}) {
    return instance({
        url: params.jsonUrl,
        method: 'get',
    });
}

export function trackPageinfo(params: AnyObject = {}) {
    return instance({
        url: '/track/api/pageinfo',
        method:'POST',
        data:params
    })
}


